
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/projectinfo.ts";

@Options({
  name: "Project",
})
export default class extends Vue {
  private searchParams = "";
  private projectInfoList = [];
  current = 0;
  selectProject: any = {};
  currentProjectId = 0;
  map: any = null;
  markers: any[] = [];
  touch: any = {};

  classList: string[] = ["entry"];
  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    const index = sessionStorage.getItem("index");
    if (index && index === "2") {
      this.classList[0] = "rightentry";
    }
  }
  @Watch("$store.state.project.projectinfo", { immediate: true })
  private projectinfoWatch(val: any) {
    this.selectProject = val;
    this.currentProjectId = val.projectId;
  }

  @Watch("searchParams")
  private searchWatch(val: string) {
    this.markers = [];
    this.fGetProjectInfo();
  }

  protected onClickSwipe(project: any) {
    this.$store.commit("project/SET_projectinfoDetail", project);
    this.$router.push({ path: "/app/projectInfo", query: { id: project.id } });
  }
  onTouch(e: string) {
    if (e === "left") {
      this.classList[0] = "leftleave";
    }
    sessionStorage.setItem("index", "1");
  }

  touchStart(e: any) {
    const touch = e.touches[0];

    //点击时的位子
    this.touch.startX = touch.pageX;
    this.touch.startY = touch.pageY;
  }
  touchMove(e: any) {
    const touch = e.touches[0];
    //滑动位置和初始位置差
    const deltaX = touch.pageX - this.touch.startX;
    if (Math.abs(deltaX) > 150 && deltaX < 0) {
      this.onTouch("left");
    }
    if (Math.abs(deltaX) > 150 && deltaX > 0) {
      this.onTouch("right");
    }
  }

  mounted() {
    this.initMap();
  }
  get projectList() {
    const list = this.$store.state.project.projectList;
    return list.map((item: any) => {
      return { text: item.projectName, value: item.projectId };
    });
  }

  initMap() {
    const win: any = window;
    const T = win.T;
    this.map = new T.Map("mapDiv");
    this.map.centerAndZoom(new T.LngLat(120.299, 31.568), 11);
    this.map.enableScrollWheelZoom();

    this.fGetProjectInfo();
  }
  async fGetProjectInfo() {
    console.log(this.classList);

    const win: any = window;
    const T = win.T;
    let res: any = await api.listIdAndNameByUserId({ name: this.searchParams });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.projectInfoList = res.data;
    if (this.projectInfoList.length > 0) {
      const zoomArr: any = [];
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that: any = this;
      this.projectInfoList.forEach((item: any) => {
        const lnglat = new T.LngLat(item.longitude, item.latitude);
        zoomArr.push(lnglat);
        const url = item.logo || require("./logo.jpg");
        console.log(url);

        //创建图片对象
        const icon = new T.Icon({
          iconUrl: url,
          iconSize: new T.Point(36, 36),
          iconAnchor: new T.Point(10, 25),
        });
        //向地图上添加自定义标注
        const marker = new T.Marker(lnglat, { icon });

        marker.addEventListener("click", function (e: any) {
          console.log(e);

          // const extData = e.target.getExtData();
          that.onClickSwipe(item);
        });
        this.map.addOverLay(marker);
      });
      this.map.setViewport(zoomArr);
    }
  }
  fSwipeChange(index: number) {
    const win: any = window;
    const T = win.T;
    this.current = index;
    let obj: any = this.projectInfoList[index];
    this.map.centerAndZoom(new T.LngLat(obj.longitude, obj.latitude), 11);
    const si = document.querySelectorAll(".swipe-item");
    const ai = document.querySelectorAll(".tdt-marker-icon");
    si.forEach((li) => {
      (li as HTMLElement).style.border = "0px";
    });
    ai.forEach((li) => {
      (li as HTMLElement).style.border = "0px";
    });
    (si[index] as HTMLElement).style.border = "1px solid red";
    (ai[index] as HTMLElement).style.border = "1px solid red";
  }
  changeSelectProject() {
    const project = this.$store.state.project.projectList.find((item: any) => {
      return item.projectId === this.currentProjectId;
    });
    if (project !== null) {
      this.$store.commit("project/SET_SELECTPROJECT", project);
    }
  }
  animationend() {
    if (this.classList[0] === "leftleave") {
      this.$router.push("/system/workbench");
    }
  }
}
